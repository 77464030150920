import React from 'react'
import axios from 'axios'

export default class KillMail extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: true,
      killmails : []
    }
  }

  componentDidMount(){
    this.fetchKillmails(772506501)
  }

  async fetchKillmails(userId) {
    this.setState({ loading: true });

    try {
      const response = await axios.get(`https://zkillboard.com/api/kills/characterID/${userId}/`);
      const lastFive = response.data.slice(0, 5);

      const fullLastFive = await Promise.all(lastFive.map(async (killmail) => {
        const exportedVictim = {
          killmail_id: killmail.killmail_id,
          destroyed: killmail.zkb.destroyedValue,
        };

        try {
          const killmailResponse = await axios.get(`https://esi.evetech.net/latest/killmails/${killmail.killmail_id}/${killmail.zkb.hash}/?datasource=tranquility`);
          const victim = killmailResponse.data.victim;

          exportedVictim.solar_system_id = killmailResponse.data.solar_system_id
          const systemResponse = await axios.get(`https://esi.evetech.net/latest/universe/systems/${exportedVictim.solar_system_id}/?datasource=tranquility&language=en`);
          exportedVictim.solar_system_name = systemResponse.data.name
          exportedVictim.solar_system_sec = systemResponse.data.security_status

          exportedVictim.ship_type_id = killmailResponse.data.victim.ship_type_id
          const shipResponse = await axios.get(`https://esi.evetech.net/latest/universe/types/${exportedVictim.ship_type_id}/?datasource=tranquility&language=en`)
          exportedVictim.ship_name = shipResponse.data.name


          if ('character_id' in victim) {
            const characterResponse = await axios.get(`https://esi.evetech.net/latest/characters/${victim.character_id}/?datasource=tranquility`);
            exportedVictim.character_name = characterResponse.data.name;
            exportedVictim.corp_id = victim.corporation_id;
          } else {
            exportedVictim.character_name = null;
            exportedVictim.corp_id = victim.corporation_id;
          }

          const corpResponse = await axios.get(`https://esi.evetech.net/latest/corporations/${victim.corporation_id}/?datasource=tranquility`);
          exportedVictim.corp_name = corpResponse.data.name;
          exportedVictim.corp_ticker = corpResponse.data.ticker;

          const corpIconResponse = await axios.get(`https://esi.evetech.net/latest/corporations/${victim.corporation_id}/icons/?datasource=tranquility`);
          exportedVictim.corp_icon = corpIconResponse.data.px64x64;

        } catch (error) {
          console.error('Error fetching details for killmail', killmail.killmail_id, error);
        }

        return exportedVictim;
      }));

      this.setState({ killmails: fullLastFive });

    } catch (error) {
      console.error('Error fetching killmails', error);
    } finally {
      this.setState({ loading: false });
    }
  }
  renderSecStatus(security_status){
    let result
    switch (security_status) {
      case 1.0:
        result = "high-sec-1"
        break;
      case 0.9:
        result = "high-sec-0-9";
        break;
      case 0.8:
        result = "high-sec-0-8";
        break;
      case 0.7:
        result = "high-sec-0-7";
        break;
      case 0.6:
        result = "high-sec-0-6";
        break;
      case 0.5:
        result = "high-sec-0-5";
        break;
      case 0.4:
        result = "low-sec-0-4";
        break;
      case 0.3:
        result = "low-sec-0-3";
        break;
      case 0.2:
        result = "low-sec-0-2";
        break;
      case 0.1:
        result = "low-sec-0-1";
        break;
      default:
        result = "null-sec-status";
    }
  return result
  }

  render(){
    if(this.state.loading){
      return(
        <div>Loading...</div>
      )
    }else{
      return(
        <div className='w-9/10 mx-auto'>
          <h1 style={{color:'#999'}} className='text-center text-4xl p-2'>Last 5 Killmails</h1>
            {this.state.killmails.map((killmail) => (
              <div className='grid grid-cols-2 m-8 p-2' style={{background: '#001600', color: '#888', borderRadius: 5}} key={killmail.killmail_id}>
                <div className='grid grid-cols-3'>
                  <div>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'ISK'}).format(killmail.destroyed)}</div>
                  <div><img style={{borderRadius: 15}} src={`https://images.evetech.net/types/${killmail.ship_type_id}/render?size=64`} alt={killmail.ship_name} /></div>
                  <div>
                    <div>{killmail.solar_system_name}</div>
                    <div className={this.renderSecStatus(killmail.solar_system_sec.toFixed(1))}>{killmail.solar_system_sec.toFixed(1)}</div>
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div><img src={killmail.corp_icon} alt='Corp Logo' /></div>
                  <div>
                    <div>{killmail.character_name ? (<div>{killmail.character_name} ({killmail.ship_name})</div>):(<div>({killmail.ship_name})</div>)} </div>
                    <div>{killmail.corp_name} ({killmail.corp_ticker})</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )
    }
  }
}