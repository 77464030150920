import './App.css';
import KillMail from './components/renderKMs';

function App() {
  return (
    <div style={{background: '#000'}} className="w-3/5 container mx-auto">
      <div className='world-rally-blue flex items-center justify-center p-5'>
        <iframe width="540" height="360" src="https://www.youtube.com/embed/wn417s1ZtxA?si=aR7LktUxXjACaDcy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div>
        <KillMail/>
      </div>
    </div>
  );
}

export default App;
